import React from "react";
import styles from "./svg.module.css";

export const ULearn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="19"
      viewBox="0 0 59 19"
      fill="none"
    >
      <path
        d="M7.54808 13.5022H2.16253C0.727776 13.5022 0 12.9616 0 11.8595V0.215088H4.32507V10.1544C4.32507 10.1752 4.34586 10.196 4.36665 10.196H5.34395C5.36475 10.196 5.38554 10.1752 5.38554 10.1544V0.215088H9.71061V11.8595C9.71061 12.9616 8.98283 13.5022 7.54808 13.5022Z"
        fill="white"
      />
      <path
        d="M25.8382 17.6401H10.7628V0.215088H15.0879V14.3339H25.8382V17.6401ZM21.5131 5.43428V3.58365C21.5131 3.56286 21.4923 3.54206 21.4715 3.54206H20.4942C20.4734 3.54206 20.4526 3.56286 20.4526 3.58365V5.43428H21.5131ZM23.6756 13.5022H18.2901C16.8553 13.5022 16.1275 12.9616 16.1275 11.8595V1.89937C16.1275 0.776515 16.8553 0.215088 18.2901 0.215088H23.6756C25.1104 0.215088 25.8382 0.776515 25.8382 1.89937V8.53253H20.4526V10.1544C20.4526 10.1752 20.4734 10.196 20.4942 10.196H21.4715C21.4923 10.196 21.5131 10.1752 21.5131 10.1544V9.36427H25.8382V11.8595C25.8382 12.9616 25.1104 13.5022 23.6756 13.5022Z"
        fill="white"
      />
      <path
        d="M32.2299 10.196V8.11665H31.211C31.1902 8.11665 31.1694 8.13745 31.1694 8.15824V10.1544C31.1694 10.1752 31.1902 10.196 31.211 10.196H32.2299ZM36.555 13.5022H29.0069C27.5721 13.5022 26.8444 12.9616 26.8444 11.8595V6.84825C26.8444 5.74618 27.5721 5.20555 29.0069 5.20555H32.2299V3.58365C32.2299 3.56286 32.2091 3.54206 32.1883 3.54206H31.211C31.1902 3.54206 31.1694 3.56286 31.1694 3.58365V4.35301H26.8444V1.89937C26.8444 0.776515 27.5721 0.215088 29.0069 0.215088H34.3924C35.8272 0.215088 36.555 0.776515 36.555 1.89937V13.5022Z"
        fill="white"
      />
      <path
        d="M58.0893 17.6401H45.1557C43.721 17.6401 42.9932 17.0995 42.9932 15.9974V8.22062C42.9932 8.19983 42.9724 8.17904 42.9516 8.17904H41.9327V13.5022H37.6077V0.215088H45.1557C46.5905 0.215088 47.2975 0.776515 47.2975 1.89937V5.76698C47.2975 6.41158 46.944 6.76507 46.237 6.84825C46.9648 6.95221 47.3183 7.3057 47.3183 7.95031V14.2924C47.3183 14.3131 47.3391 14.3339 47.3598 14.3339H58.0893V17.6401ZM42.9932 5.49666V3.58365C42.9932 3.56286 42.9724 3.54206 42.9516 3.54206H41.9327V5.53825H42.9516C42.9724 5.53825 42.9932 5.51746 42.9932 5.49666ZM58.0893 13.5022H53.7643V3.58365C53.7643 3.56286 53.7435 3.54206 53.7227 3.54206H52.7454C52.7246 3.54206 52.7038 3.56286 52.7038 3.58365V13.5022H48.3787V0.215088H52.7038V1.06763C53.4316 0.506199 54.5128 0.215088 55.9268 0.215088C57.3616 0.215088 58.0893 0.776515 58.0893 1.89937V13.5022Z"
        fill="white"
      />
      <path
        d="M4.34586 18.7849H0.0207936V5.49781H4.34586V18.7849Z"
        fill="white"
      />
    </svg>
  );
};

export const GTALogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218"
      height="25"
      viewBox="0 0 218 25"
      fill="none"
    >
      <g filter="url(#filter0_d_70_4563)">
        <path
          d="M11.364 18.1004H3.3775C1.8594 18.1004 1.08936 17.5283 1.08936 16.3622V5.82358C1.08936 4.6355 1.8594 4.04146 3.3775 4.04146H9.07586C10.594 4.04146 11.364 4.6355 11.364 5.82358V8.19973H6.78772V7.60569C6.78772 7.58369 6.76571 7.56169 6.74371 7.56169H5.70965C5.68765 7.56169 5.66565 7.58369 5.66565 7.60569V14.5581C5.66565 14.5801 5.68765 14.6021 5.70965 14.6021H6.78772V9.49781H11.364V18.1004Z"
          fill="white"
        />
        <path
          d="M34.1052 22.4786H20.4643C18.9462 22.4786 18.1762 21.9066 18.1762 20.7405V12.512C18.1762 12.49 18.1542 12.468 18.1322 12.468H17.0541V18.1004H12.4778V4.04146H20.4643C21.9824 4.04146 22.7305 4.6355 22.7305 5.82358V9.91583C22.7305 10.5979 22.3564 10.9719 21.6084 11.0599C22.3785 11.1699 22.7525 11.5439 22.7525 12.226V18.9364C22.7525 18.9584 22.7745 18.9804 22.7965 18.9804H34.1052V22.4786ZM18.1762 9.62982V7.60569C18.1762 7.58369 18.1542 7.56169 18.1322 7.56169H17.0541V9.67382H18.1322C18.1542 9.67382 18.1762 9.65182 18.1762 9.62982ZM29.5289 14.6021V12.402H28.4508C28.4288 12.402 28.4068 12.424 28.4068 12.446V14.5581C28.4068 14.5801 28.4288 14.6021 28.4508 14.6021H29.5289ZM34.1052 18.1004H26.1187C24.6006 18.1004 23.8305 17.5283 23.8305 16.3622V11.0599C23.8305 9.89383 24.6006 9.3218 26.1187 9.3218H29.5289V7.60569C29.5289 7.58369 29.5069 7.56169 29.4849 7.56169H28.4508C28.4288 7.56169 28.4068 7.58369 28.4068 7.60569V8.41974H23.8305V5.82358C23.8305 4.6355 24.6006 4.04146 26.1187 4.04146H31.817C33.3351 4.04146 34.1052 4.6355 34.1052 5.82358V18.1004Z"
          fill="white"
        />
        <path
          d="M56.8586 22.4786H43.2397C41.6996 22.4786 40.9296 21.9066 40.9296 20.7405V7.60569C40.9296 7.58369 40.9076 7.56169 40.8856 7.56169H39.8515C39.8295 7.56169 39.8075 7.58369 39.8075 7.60569V18.1004H35.2312V4.04146H39.8075V4.94352C40.5776 4.34948 41.7216 4.04146 43.2177 4.04146C44.7358 4.04146 45.5059 4.6355 45.5059 5.82358V18.9364C45.5059 18.9584 45.5279 18.9804 45.5499 18.9804H56.8586V22.4786ZM56.8586 18.1004H48.8721C47.288 18.1004 46.5839 17.5283 46.5839 16.3622V5.82358C46.5839 4.6355 47.354 4.04146 48.8721 4.04146H52.2823V0.52124H56.8586V18.1004ZM52.2823 14.6021V7.56169H51.2042C51.1822 7.56169 51.1602 7.58369 51.1602 7.60569V14.5581C51.1602 14.5801 51.1822 14.6021 51.2042 14.6021H52.2823Z"
          fill="white"
        />
        <path
          d="M71.5734 18.1004H65.875C64.3569 18.1004 63.5869 17.5283 63.5869 16.3622V8.41974H62.4648V4.94352H63.5869V4.04146H68.1632V4.94352H73.8615V8.41974H68.1632V14.5581C68.1632 14.5801 68.1852 14.6021 68.2072 14.6021H69.2632C69.2852 14.6021 69.3072 14.5801 69.3072 14.5581V9.3218H73.8615V16.3622C73.8615 17.5283 73.0915 18.1004 71.5734 18.1004Z"
          fill="white"
        />
        <path
          d="M80.6342 9.56381V7.60569C80.6342 7.58369 80.6122 7.56169 80.5902 7.56169H79.5561C79.5341 7.56169 79.5121 7.58369 79.5121 7.60569V9.56381H80.6342ZM82.9223 18.1004H77.224C75.7059 18.1004 74.9358 17.5283 74.9358 16.3622V5.82358C74.9358 4.6355 75.7059 4.04146 77.224 4.04146H82.9223C84.4404 4.04146 85.2105 4.6355 85.2105 5.82358V12.842H79.5121V14.5581C79.5121 14.5801 79.5341 14.6021 79.5561 14.6021H80.5902C80.6122 14.6021 80.6342 14.5801 80.6342 14.5581V13.7221H85.2105V16.3622C85.2105 17.5283 84.4404 18.1004 82.9223 18.1004Z"
          fill="white"
        />
        <path
          d="M94.2453 18.1004H88.547C87.0289 18.1004 86.2588 17.5283 86.2588 16.3622V5.82358C86.2588 4.6355 87.0289 4.04146 88.547 4.04146H94.2453C95.7634 4.04146 96.5335 4.6355 96.5335 5.82358V9.98184H91.9572V7.60569C91.9572 7.58369 91.9352 7.56169 91.9132 7.56169H90.8791C90.8571 7.56169 90.8351 7.58369 90.8351 7.60569V14.5581C90.8351 14.5801 90.8571 14.6021 90.8791 14.6021H91.9132C91.9352 14.6021 91.9572 14.5801 91.9572 14.5581V11.2799H96.5335V16.3622C96.5335 17.5283 95.7634 18.1004 94.2453 18.1004Z"
          fill="white"
        />
        <path
          d="M107.9 18.1004H103.324V7.60569C103.324 7.58369 103.302 7.56169 103.28 7.56169H102.202V18.1004H97.6258V0.52124H102.202V4.04146H105.612C107.13 4.04146 107.9 4.6355 107.9 5.82358V18.1004Z"
          fill="white"
        />
        <path
          d="M119.588 9.3218V7.60569C119.588 7.58369 119.566 7.56169 119.544 7.56169H118.51C118.488 7.56169 118.466 7.58369 118.466 7.60569V9.3218H119.588ZM135.517 22.4786H121.876C120.358 22.4786 119.588 21.9066 119.588 20.7405V12.842H118.466V18.1004H113.89V5.82358C113.89 4.6355 114.66 4.04146 116.178 4.04146H121.876C123.395 4.04146 124.165 4.6355 124.165 5.82358V18.9364C124.165 18.9584 124.187 18.9804 124.209 18.9804H135.517V22.4786ZM135.517 18.1004H127.531C125.947 18.1004 125.243 17.5283 125.243 16.3622V5.82358C125.243 4.6355 126.013 4.04146 127.531 4.04146H130.941V0.52124H135.517V18.1004ZM130.941 14.6021V7.56169H129.863C129.841 7.56169 129.819 7.58369 129.819 7.60569V14.5581C129.819 14.5801 129.841 14.6021 129.863 14.6021H130.941Z"
          fill="white"
        />
        <path
          d="M145.29 18.1004H138.447L136.181 4.04146H140.824L141.99 12.534L143.134 4.04146H147.6L145.29 18.1004Z"
          fill="white"
        />
        <path
          d="M153.944 9.56381V7.60569C153.944 7.58369 153.922 7.56169 153.9 7.56169H152.865C152.843 7.56169 152.821 7.58369 152.821 7.60569V9.56381H153.944ZM156.232 18.1004H150.533C149.015 18.1004 148.245 17.5283 148.245 16.3622V5.82358C148.245 4.6355 149.015 4.04146 150.533 4.04146H156.232C157.75 4.04146 158.52 4.6355 158.52 5.82358V12.842H152.821V14.5581C152.821 14.5801 152.843 14.6021 152.865 14.6021H153.9C153.922 14.6021 153.944 14.5801 153.944 14.5581V13.7221H158.52V16.3622C158.52 17.5283 157.75 18.1004 156.232 18.1004Z"
          fill="white"
        />
        <path
          d="M181.966 22.4786H167.621C166.081 22.4786 165.31 21.9066 165.31 20.7405V7.60569C165.31 7.58369 165.288 7.56169 165.266 7.56169H164.232C164.21 7.56169 164.188 7.58369 164.188 7.60569V18.1004H159.612V4.04146H164.188V4.94352C164.958 4.34948 166.103 4.04146 167.599 4.04146C169.117 4.04146 169.887 4.6355 169.887 5.82358V18.9364C169.887 18.9584 169.909 18.9804 169.931 18.9804H181.966V22.4786ZM179.677 18.1004H173.979C172.461 18.1004 171.691 17.5283 171.691 16.3622V8.41974H170.569V4.94352H171.691V4.04146H176.267V4.94352H181.966V8.41974H176.267V14.5581C176.267 14.5801 176.289 14.6021 176.311 14.6021H177.367C177.389 14.6021 177.411 14.5801 177.411 14.5581V9.3218H181.966V16.3622C181.966 17.5283 181.195 18.1004 179.677 18.1004Z"
          fill="white"
        />
        <path
          d="M191.061 18.1004H185.362C183.844 18.1004 183.074 17.5283 183.074 16.3622V4.04146H187.65V14.5581C187.65 14.5801 187.672 14.6021 187.694 14.6021H188.728C188.75 14.6021 188.772 14.5801 188.772 14.5581V4.04146H193.349V16.3622C193.349 17.5283 192.579 18.1004 191.061 18.1004Z"
          fill="white"
        />
        <path
          d="M216.089 22.4786H202.449C200.93 22.4786 200.16 21.9066 200.16 20.7405V12.512C200.16 12.49 200.138 12.468 200.116 12.468H199.038V18.1004H194.462V4.04146H202.449C203.967 4.04146 204.715 4.6355 204.715 5.82358V9.91583C204.715 10.5979 204.341 10.9719 203.593 11.0599C204.363 11.1699 204.737 11.5439 204.737 12.226V18.9364C204.737 18.9584 204.759 18.9804 204.781 18.9804H216.089V22.4786ZM200.16 9.62982V7.60569C200.16 7.58369 200.138 7.56169 200.116 7.56169H199.038V9.67382H200.116C200.138 9.67382 200.16 9.65182 200.16 9.62982ZM211.513 9.56381V7.60569C211.513 7.58369 211.491 7.56169 211.469 7.56169H210.435C210.413 7.56169 210.391 7.58369 210.391 7.60569V9.56381H211.513ZM213.801 18.1004H208.103C206.585 18.1004 205.815 17.5283 205.815 16.3622V5.82358C205.815 4.6355 206.585 4.04146 208.103 4.04146H213.801C215.319 4.04146 216.089 4.6355 216.089 5.82358V12.842H210.391V14.5581C210.391 14.5801 210.413 14.6021 210.435 14.6021H211.469C211.491 14.6021 211.513 14.5801 211.513 14.5581V13.7221H216.089V16.3622C216.089 17.5283 215.319 18.1004 213.801 18.1004Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_70_4563"
          x="0.155146"
          y="0.52124"
          width="216.868"
          height="23.8257"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.934209" />
          <feGaussianBlur stdDeviation="0.467105" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_70_4563"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_70_4563"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const PinkShade = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 118"
      fill="none"
      className={styles.pinkShade}
    >
      <path
        d="M319.64 0.106689L232.922 117.107L79.933 117.107L0.640137 0.106689L160.14 0.106689L319.64 0.106689Z"
        fill="url(#paint0_linear_223_36)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_223_36"
          x1="160.903"
          y1="186.121"
          x2="160.903"
          y2="19.7864"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFBBE8" />
          <stop offset="1" stop-color="#FF00A8" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const YellowShade = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={styles.pinkShade}
      viewBox="0 0 319 117"
      fill="none"
    >
      <path
        d="M319 0L232.282 117L79.2929 117L0 0L159.5 0L319 0Z"
        fill="url(#paint0_linear_201_2)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_201_2"
          x1="160.263"
          y1="186.014"
          x2="160.263"
          y2="19.6797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E3BA7C" />
          <stop offset="1" stop-color="#E5BB7D" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="19"
      viewBox="0 0 11 19"
      fill="none"
    >
      <path
        d="M6.73919 18.0167V9.82068H9.5042L9.91519 6.61168H6.73919V4.56768C6.73919 3.64168 6.99719 3.00768 8.32619 3.00768H10.0102V0.146681C9.19019 0.0586813 8.36719 0.0166812 7.54319 0.0196812C5.09919 0.0196812 3.4212 1.51168 3.4212 4.25068V6.60568H0.674194V9.81468H3.42719V18.0167H6.73919Z"
        fill="white"
      />
    </svg>
  );
};

export const Youtube = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
    >
      <path
        d="M20.267 2.21497C20.1531 1.79237 19.9305 1.40697 19.6214 1.09714C19.3123 0.787301 18.9274 0.563831 18.505 0.44897C16.939 0.0189703 10.675 0.0119705 10.675 0.0119705C10.675 0.0119705 4.41004 0.00497028 2.84304 0.41597C2.42096 0.536117 2.03686 0.762747 1.7276 1.0741C1.41835 1.38546 1.19432 1.77109 1.07704 2.19397C0.664036 3.75997 0.660036 7.00797 0.660036 7.00797C0.660036 7.00797 0.656036 10.272 1.06604 11.822C1.29604 12.679 1.97104 13.356 2.82904 13.587C4.41104 14.017 10.659 14.024 10.659 14.024C10.659 14.024 16.924 14.031 18.49 13.621C18.9125 13.5063 19.2978 13.2834 19.6077 12.9742C19.9176 12.6649 20.1414 12.2802 20.257 11.858C20.671 10.293 20.674 7.04597 20.674 7.04597C20.674 7.04597 20.694 3.78097 20.267 2.21497ZM8.67004 10.017L8.67504 4.01697L13.882 7.02197L8.67004 10.017Z"
        fill="white"
      />
    </svg>
  );
};

export const Twitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
    >
      <path
        d="M15.2371 0H18.1209L11.8203 7.20186L19.233 17H13.4292L8.88391 11.0567L3.68195 17H0.796505L7.53601 9.29659L0.425049 0.0007837H6.37618L10.4849 5.43314L15.2371 0ZM14.2254 15.2744H15.8232L5.50789 1.6355H3.79323L14.2254 15.2744Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M13 7L7 1L1 7"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const PersonSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={styles.timelinesvg}
      viewBox="0 0 35 33"
      fill="none"
    >
      <circle cx="16.4511" cy="9.91703" r="9.13889" fill="#E56EFC" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.1185 32.4593H0C0.000166989 23.0379 7.63779 15.4003 17.0593 15.4003C26.4807 15.4003 34.1184 23.0379 34.1185 32.4593Z"
        fill="#E56EFC"
      />
    </svg>
  );
};
export const ComputerSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={styles.timelinesvg}
      viewBox="0 0 47 36"
      fill="none"
    >
      <path
        d="M40.8247 28.8014H40.8251C41.505 28.8006 42.1558 28.5256 42.6351 28.0385C43.1142 27.5515 43.3828 26.8923 43.3836 26.2057V26.2054V3.2914V3.29105C43.3828 2.60447 43.1142 1.94525 42.6351 1.45828C42.1558 0.971132 41.505 0.696176 40.8251 0.69537H40.8247L6.25939 0.69537L6.25903 0.695371C5.57909 0.696176 4.92824 0.971132 4.44899 1.45828C3.96991 1.94525 3.70128 2.60447 3.7005 3.29105V3.2914L3.7005 26.2054L3.7005 26.2057C3.70128 26.8923 3.96991 27.5515 4.44899 28.0385C4.92824 28.5256 5.57908 28.8006 6.25903 28.8014H6.25939H40.8247ZM7.31544 4.35983H39.7686V25.1369H7.31544V4.35983Z"
        fill="#E56EFC"
        stroke="#E56EFC"
        stroke-width="0.609259"
      />
      <path
        d="M1 31.2461H0.69537V31.5507V34.6059V34.9105H1H46.0852H46.3898V34.6059V31.5507V31.2461H46.0852H1Z"
        fill="#E56EFC"
        stroke="#E56EFC"
        stroke-width="0.609259"
      />
    </svg>
  );
};
export const CompusSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 51"
      fill="none"
      className={styles.timelinesvg}
    >
      <path
        d="M24.9059 0C11.2077 0 0 11.3923 0 25.3162C0 39.2401 11.2077 50.6324 24.9059 50.6324C38.6042 50.6324 49.8118 39.2401 49.8118 25.3162C49.8118 11.3923 38.6042 0 24.9059 0ZM24.9059 6.32905C35.2419 6.32905 43.5853 14.81 43.5853 25.3162C43.5853 35.8225 35.2419 44.3034 24.9059 44.3034C14.57 44.3034 6.22648 35.8225 6.22648 25.3162C6.22648 14.81 14.57 6.32905 24.9059 6.32905ZM37.3589 12.6581L18.6794 18.9872L12.453 37.9743L31.1324 31.6453L37.3589 12.6581ZM24.9059 22.1517C26.6493 22.1517 28.0191 23.5441 28.0191 25.3162C28.0191 27.0884 26.6493 28.4807 24.9059 28.4807C23.1625 28.4807 21.7927 27.0884 21.7927 25.3162C21.7927 23.5441 23.1625 22.1517 24.9059 22.1517Z"
        fill="#E56EFC"
      />
    </svg>
  );
};

export const ClickOpenSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={styles.clickOpenSvg}
      viewBox="0 0 18 17"
      fill="none"
    >
      <path
        d="M13.2349 9.1875V13.3125C13.2349 13.6771 13.09 14.0269 12.8321 14.2848C12.5742 14.5426 12.2245 14.6875 11.8599 14.6875H4.29736C3.93269 14.6875 3.58295 14.5426 3.32509 14.2848C3.06723 14.0269 2.92236 13.6771 2.92236 13.3125V5.75C2.92236 5.38533 3.06723 5.03559 3.32509 4.77773C3.58295 4.51986 3.93269 4.375 4.29736 4.375H8.42236"
        stroke="white"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.1724 2.3125H15.2974V6.4375"
        stroke="white"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.73486 9.875L15.2974 2.3125"
        stroke="white"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const MailLogoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 15 12"
      fill="none"
    >
      <path
        d="M13.6906 0.791748H1.30968C1.04629 0.791748 0.833496 1.00454 0.833496 1.26794V10.7917C0.833496 11.0551 1.04629 11.2679 1.30968 11.2679H13.6906C13.954 11.2679 14.1667 11.0551 14.1667 10.7917V1.26794C14.1667 1.00454 13.954 0.791748 13.6906 0.791748ZM12.4882 2.41227L7.79327 6.06553C7.6772 6.1563 7.515 6.1563 7.39893 6.06553L2.70253 2.41227C2.68483 2.39861 2.67184 2.37975 2.6654 2.35834C2.65895 2.33692 2.65937 2.31403 2.66659 2.29287C2.6738 2.2717 2.68747 2.25332 2.70565 2.24031C2.72384 2.2273 2.74565 2.22031 2.76801 2.22031H12.4227C12.4451 2.22031 12.4669 2.2273 12.4851 2.24031C12.5033 2.25332 12.5169 2.2717 12.5241 2.29287C12.5314 2.31403 12.5318 2.33692 12.5253 2.35834C12.5189 2.37975 12.5059 2.39861 12.4882 2.41227Z"
        fill="white"
      />
    </svg>
  );
};

export const PhoneLogoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 14 13"
      fill="none"
    >
      <path
        d="M2.93968 0.0297852C2.38108 0.0297852 1.84537 0.251686 1.45038 0.646671C1.0554 1.04166 0.833496 1.57737 0.833496 2.13597L0.833496 3.82091C0.833496 8.70641 4.79396 12.6669 9.67946 12.6669H11.3644C11.923 12.6669 12.4587 12.445 12.8537 12.05C13.2487 11.655 13.4706 11.1193 13.4706 10.5607V9.39639C13.4706 9.16158 13.4051 8.93142 13.2816 8.73172C13.1581 8.53202 12.9814 8.37068 12.7713 8.26579L10.7368 7.24809C10.5748 7.16711 10.3973 7.12189 10.2163 7.11549C10.0353 7.1091 9.85506 7.14168 9.68776 7.21102C9.52047 7.28037 9.37003 7.38486 9.24665 7.51743C9.12326 7.64999 9.02981 7.80752 8.97262 7.97935L8.72156 8.73168C8.67536 8.86975 8.58039 8.98622 8.45445 9.05927C8.32852 9.13232 8.18026 9.15693 8.03748 9.12849C7.13301 8.94757 6.30232 8.50304 5.65002 7.85089C4.99773 7.19875 4.55301 6.36815 4.37188 5.46373C4.34344 5.32095 4.36805 5.17269 4.4411 5.04676C4.51415 4.92082 4.63062 4.82585 4.76868 4.77964L5.70299 4.46793C6.00492 4.3671 6.25768 4.15604 6.41075 3.87693C6.56381 3.59782 6.60591 3.27123 6.52861 2.96243L6.03492 0.987676C5.96674 0.714309 5.80916 0.471566 5.58722 0.298013C5.36528 0.12446 5.09171 0.0300508 4.80997 0.0297852L2.93968 0.0297852Z"
        fill="white"
      />
    </svg>
  );
};
export const BackButtonSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="28"
      viewBox="0 0 329 284"
      fill="none"
    >
      <path
        d="M316 269.308C278.936 224.062 246.022 198.391 217.252 192.285C188.49 186.187 161.106 185.263 135.094 189.52V270.55L13 138.328L135.094 13V90.015C183.188 90.3938 224.07 107.65 257.748 141.775C291.419 175.9 310.841 218.411 316 269.308Z"
        stroke="white"
        stroke-width="25"
        stroke-linejoin="round"
      />
    </svg>
  );
};
